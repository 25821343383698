import client from '../controller/client';

export const getListingById = async (listingId) => {
  try {
    const response = await client.get(`/api/_listings/${listingId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting listing by id:', error);
    throw new Error('Failed to get listing by id');
  }
};
