import React from 'react';
import { calculatePrices, formatPrice } from '../helpers/index';
import './styles/OptionCard.css';

export const OptionCard = ({ optionInfo, isSelected, onPress }) => {
  // Calculate prices for the option
  const { originalPrice, finalPrice } = calculatePrices(optionInfo);

  // Determine if discounts are applied
  const hasDiscounts =
    Array.isArray(optionInfo.discounts) &&
    optionInfo.discounts.length > 0 &&
    originalPrice &&
    finalPrice !== 'Price upon request';

  return (
    <div
      className={`option-card ${isSelected ? 'selected' : ''}`}
      onClick={onPress}>
      <div className='price-container'>
        <p
          className='option-name'
          title={optionInfo.name}>
          {optionInfo.name}
        </p>
        {hasDiscounts ? (
          <div className='price-container'>
            <p className='final-price'>${formatPrice(finalPrice, true)}</p>
            <p className='original-price'>${formatPrice(originalPrice, true)}</p>
          </div>
        ) : (
          <p
            className={finalPrice !== 'Price upon request' ? 'single-price' : 'no-price-text'}
            title={finalPrice}>
            {finalPrice !== 'Price upon request' ? `$${formatPrice(finalPrice, true)}` : finalPrice}
          </p>
        )}
      </div>
    </div>
  );
};
