import React from 'react';
import { OptionCard } from './OptionCard';
import './styles/OptionsRow.css';

export const OptionsRow = ({ options, selectedOptionId, onSelectOption }) => {
  return (
    <div className='options-row'>
      {options.map((item, index) => (
        <div
          className='option-container'
          key={item.variant_id || index}>
          <OptionCard
            optionInfo={item}
            isSelected={selectedOptionId === item.variant_id}
            onPress={() => onSelectOption(item.variant_id)}
          />
        </div>
      ))}
    </div>
  );
};
