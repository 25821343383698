import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PreferencesContext } from '../context/theme';
import { MasonryListings } from '../components/MasonryListings';
import { ButtonGeneral } from '../components/buttonGeneral';
import { CustomModal } from '../components/CustomModal';
import { SeeMoreText } from '../components/SeeMoreText';
import { PieIcons } from '../components/icons/PIE_Icons';
import { daysOfWeek } from '../constants/daysOfWeek';
import { getSellerById } from '../api/sellerApi';
import { incrementViewCount } from '../api/metricsApi';
import { formatAddress, openMap } from '../helpers';
import logo from '../PIELogo.png';
import './styles/SellerStore.css';
import { AppBanner } from '../components/AppBanner';

const SellerStore = () => {
  const { theme } = useContext(PreferencesContext);
  const { sellerId } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    seller,
    listings,
    address_line_1,
    address_line_2,
    city,
    zip_code,
    location_hours,
    category,
  } = data || {};
  const [hoursModal, setHoursModal] = useState(false);

  // Increment view count if appropriate
  useEffect(() => {
    if (sellerId) {
      incrementViewCount(sellerId, true).catch((error) => {
        console.error('Failed to increment view count:', error);
      });
    }
  }, [sellerId]);

  // Fallback: fetch seller data if not already provided
  useEffect(() => {
    if (sellerId) {
      setIsLoading(true);
      getSellerById(sellerId)
        .then((response) => {
          setData(response);
        })
        .catch((err) => {
          console.error('Error fetching seller by ID:', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [sellerId]);

  const onShareStore = async (sellerId) => {
    try {
      if (!sellerId) {
        console.error('Seller ID is missing.');
        return;
      }
      const shareUrl = `https://pienet.net/seller/${sellerId}`;
      if (navigator.share) {
        await navigator.share({
          title: 'Check out my PIE store!',
          text: `Shop at ${seller?.seller_name}'s store on PIE!`,
          url: shareUrl,
        });
      } else {
        // Fallback for browsers that don't support the Web Share API
        navigator.clipboard.writeText(shareUrl);
        alert('Store link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing store:', error);
    }
  };

  const formatTime = (time) => {
    if (!time) return 'Closed';
    const [hour, minute] = time.split(':');
    const date = new Date();
    date.setHours(parseInt(hour), parseInt(minute));
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  if (isLoading) {
    return (
      <div className='loading-container'>
        <p>Loading...</p>
      </div>
    );
  }

  if (!data) {
    return (
      <div className='loading-container'>
        <p>No seller data found.</p>
      </div>
    );
  }

  const LocationHours = () => {
    const hours = location_hours;

    if (!hours || hours.length === 0) {
      return (
        <div className='hours-column'>
          <p className='no-hours-text'>No business hours are set.</p>
        </div>
      );
    }

    return (
      <div className='hours-column'>
        {hours.map((hour) => {
          const day = daysOfWeek.find((d) => d.value === hour.day_of_week);
          return (
            <div
              key={hour.id}
              className='hour-row'>
              <p className='day-text'>{day ? day.label : 'Unknown'}</p>
              <p className='time-text'>
                {hour.open_time && hour.close_time
                  ? `${formatTime(hour.open_time)} - ${formatTime(hour.close_time)}`
                  : 'Closed'}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {' '}
      <AppBanner />
      <div className='seller-store container'>
        {/* Profile Header */}
        <div className='profile-header'>
          <div className='seller-row'>
            <img
              src={seller?.avatar_url || logo}
              alt='Seller Avatar'
              className='avatar'
            />
            <div className='seller-info'>
              <div className='item-row'>
                <PieIcons
                  name='market'
                  draw={{
                    color: theme.colors.onBackground,
                    focused: true,
                    size: 16,
                  }}
                />
                <p
                  className='title seller-name'
                  style={{ marginLeft: '5px' }}
                  title={seller?.seller_name}>
                  {seller?.seller_name}
                </p>
              </div>
              <div className='item-row'>
                <PieIcons
                  name='categories'
                  draw={{
                    color: theme.colors.onBackground,
                    focused: true,
                    size: 16,
                  }}
                />
                <p
                  className='category-text'
                  style={{ marginLeft: '5px' }}>
                  {category}
                </p>
              </div>
              <div className='item-row'>
                <PieIcons
                  name='location'
                  draw={{ size: 18 }}
                />
                <p
                  className='address-text'
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    openMap(address_line_1, city, zip_code, seller.seller_name);
                  }}>
                  {formatAddress(address_line_1, address_line_2, city)}
                </p>
              </div>
              <div className='item-row'>
                <PieIcons
                  name='clock'
                  draw={{ size: 18 }}
                />
                <p
                  className='address-text'
                  style={{ marginLeft: '5px' }}
                  onClick={() => setHoursModal(true)}>
                  Business Hours
                </p>
              </div>
            </div>
            <div className='button-group'>
              <div className='button-container'>
                <ButtonGeneral
                  title='Share'
                  width
                  color={theme.colors.pieBlue}
                  onPress={() => onShareStore(seller?.seller_id)}
                  margin={5}
                />
              </div>
            </div>
          </div>
          {seller?.seller_description ? (
            <div className='description-container'>
              <h3 className='title'>About</h3>
              <SeeMoreText text={seller?.seller_description} />
            </div>
          ) : null}
        </div>

        {/* Listings Section */}
        <div className='profile-listings'>
          <div className='tabs-container'>
            <p className='tab-text'>Listings</p>
          </div>
          <div className='content-container'>
            <MasonryListings
              listings={listings}
              scrollEnabled={false}
            />
          </div>
        </div>

        <CustomModal
          close={() => setHoursModal(false)}
          onPress={() => setHoursModal(false)}
          shown={hoursModal}
          color={theme.colors.lightGray}
          buttonText='Close'
          component={
            <div className='hours-modal-content'>
              <LocationHours />
            </div>
          }
          title='Business Hours'
        />
      </div>
    </>
  );
};

export default SellerStore;
