import React, { useCallback } from 'react';
import ListingCard from './ListingCard';
import './styles/MasonryListings.css';

export const MasonryListings = ({
  listings,
  onRefresh,
  refreshing,
  scroll = false,
  footer,
  empty,
  onEndReached,
}) => {
  const renderItem = useCallback(
    (item, index) => (
      <ListingCard
        key={`${item.id}-${index}`}
        listing={item}
      />
    ),
    [],
  );

  // Optional scroll handler for "infinite scroll" behavior.
  const handleScroll = (e) => {
    if (onEndReached) {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      if (scrollTop + clientHeight >= scrollHeight * 0.5) {
        onEndReached();
      }
    }
  };

  return (
    <div
      className={`masonry-listings-container ${scroll ? 'scroll-enabled' : ''}`}
      onScroll={scroll ? handleScroll : undefined}>
      {onRefresh && (
        <button
          className='refresh-button'
          onClick={onRefresh}
          disabled={refreshing}>
          {refreshing ? 'Refreshing...' : 'Refresh'}
        </button>
      )}
      {listings && listings.length > 0 ? (
        <div className='masonry-listings'>
          {listings.map((item, index) => renderItem(item, index))}
        </div>
      ) : (
        empty || <p className='empty-message'>No listings available</p>
      )}
      {footer && <div className='list-footer'>{footer}</div>}
    </div>
  );
};

export default MasonryListings;
