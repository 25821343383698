import client from '../controller/client';

export const incrementViewCount = async (id, isStore = false) => {
  try {
    const response = await client.post(`/api/metrics/incrementViewCount/${id}`, { isStore });
    return response.data;
  } catch (error) {
    console.error('Error incrementing view count:', error);
    throw new Error('Failed to increment view count');
  }
};
