import React, { useRef } from 'react';
import './styles/LandingPage.css';
import logo from '../PIELogo.png';
import iPhoneImage from '../components/images/iPhoneImage.png';
import iPhoneNearby from '../components/images/iPhoneNearby.png';
import iPhoneCategories from '../components/images/iPhoneCategories.png';
import iPhoneStore from '../components/images/iPhoneStore.png';
import appStoreBadge from '../components/images/appStoreWhite.svg';
import googlePlayBadge from '../components/images/GooglePlayBadge.png';
import { Link } from 'react-router-dom';
import audi from '../components/images/audiLogo.png';
import kw from '../components/images/kwLogo.png';
import tristar from '../components/images/tristarLogo.jpeg';
import ufc from '../components/images/ufcLogo.png';
import ms from '../components/images/morganStanleyLogo.png';
import { AppBanner } from '../components/AppBanner';

import { FaInstagram } from 'react-icons/fa';
import { GoChevronDown, GoChevronUp } from 'react-icons/go';

const LandingPage = () => {
  const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  const sections = [
    {
      id: 1,
      title: (
        <>
          Shop Local Products <br />& Services
        </>
      ),
      subtitle: 'Find products & services you’ll love near you.',
      image: iPhoneImage,
      imageFirst: true,
      backgroundColor: '#8be88b',
      isDark: false,
    },
    {
      id: 2,
      title: 'Your Local Marketplace',
      subtitle: 'Browse and find exactly what you need around you.',
      image: iPhoneCategories,
      imageFirst: false,
      backgroundColor: '#000000',
      isDark: true,
    },
    {
      id: 3,
      title: 'Exclusive Local Deals',
      subtitle: 'Access unbeatable offers happening near you.',
      image: iPhoneNearby,
      imageFirst: true,
      backgroundColor: '#000000',
      isDark: true,
    },
    {
      id: 4,
      title: 'Own A Small Business?',
      subtitle: 'Sign Up. Upload Your Inventory. Reach More Customers!',
      image: iPhoneStore,
      imageFirst: false,
      backgroundColor: '#8be88b',
      isDark: false,
    },
    {
      id: 5,
      isFooter: true,
      backgroundColor: '#f0f0f0',
      isDark: true,
    },
  ];

  const scrollToSection = (index) => {
    sectionRefs[index]?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div className='landing-container'>
      {/* Header */}
      <header className='landing-header'>
        <AppBanner />
        <img
          src={logo}
          alt='Shop On PIE Logo'
          className='header-logo'
        />
        {/* <div className='header-buttons'>
          <ButtonGeneral
            title='Sign Up'
            onPress={() => navigate('/sign-up')}
            fontSize={14}
            height={40}
            color={theme.colors.pieGreen}
            radius={20}
            margin={10}
          />
          <ButtonGeneral
            title='Login'
            onPress={() => navigate('/login')}
            fontSize={14}
            height={40}
            border={true}
            radius={20}
            margin={10}
            color={'rgba(128, 128, 128, 0.2)'}
            titleColor={'#ffffff'}
          />
        </div> */}
      </header>

      {/* Sections */}
      <div className='sections-wrapper'>
        {sections.map((section, index) => (
          <section
            key={section.id}
            id={section.id}
            className={`landing-section ${section.isFooter ? 'landing-footer-section' : ''}`}
            ref={sectionRefs[index]}
            style={{
              backgroundColor: section.backgroundColor,
              minHeight: section.isFooter ? '40vh' : '100vh',
            }}>
            {section.isFooter ? (
              <>
                <div className='trusted-section'>
                  <h2 className='trusted-title'>Affiliates</h2>
                  <div className='logo-carousel'>
                    {[audi, kw, tristar, ufc, ms].map((company, idx) => (
                      <img
                        key={idx}
                        src={company}
                        alt='Trusted Company Logo'
                        className='trusted-logo'
                      />
                    ))}
                  </div>
                </div>

                {/* Footer content */}

                <footer className='landing-footer'>
                  <div className='footer-left'>
                    <span>PIE Network Inc</span>
                    <Link
                      to='/terms'
                      className='footer-link'>
                      Terms & Conditions
                    </Link>
                    <Link
                      to='/privacy'
                      className='footer-link'>
                      Privacy
                    </Link>
                    <Link
                      to='/contact'
                      className='footer-link'>
                      Contact Us
                    </Link>
                  </div>
                  <div className='footer-right'>
                    <a
                      href='https://www.instagram.com/your_instagram_page'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='instagram-link'>
                      <FaInstagram className='instagram-icon' />
                    </a>
                  </div>
                </footer>
                {/* Removed the Back to Top button from here */}
              </>
            ) : (
              <>
                <div
                  className={`section-content ${
                    section.imageFirst ? 'image-left' : 'image-right'
                  }`}>
                  <div
                    className='text-content'
                    style={{ color: section.isDark ? '#ffffff' : '#000000' }}>
                    <h1 className='section-title'>{section.title}</h1>
                    <p className='section-subtitle'>{section.subtitle}</p>
                    <div className='badges-row'>
                      <a
                        href='https://play.google.com/store/apps/details?id=com.j.pie.net.PIEMAIN'
                        target='_blank'
                        rel='noopener noreferrer'>
                        <img
                          src={googlePlayBadge}
                          alt='Google Play Badge'
                          className='store-badge'
                        />
                      </a>
                      <a
                        href='https://apps.apple.com/us/app/shop-on-pie/id6504500912'
                        target='_blank'
                        rel='noopener noreferrer'>
                        <img
                          src={appStoreBadge}
                          alt='App Store Badge'
                          className='store-badge'
                        />
                      </a>
                    </div>
                  </div>
                  <div className='image-content'>
                    <img
                      src={section.image}
                      alt={section.title}
                      className='section-image'
                    />
                  </div>
                </div>
                {/* Scroll Button */}
                {index === sections.length - 2 ? (
                  // Next-to-last section, show Back to Top button
                  <button
                    className='scroll-button'
                    onClick={() => scrollToSection(0)}>
                    <div className='scroll-button-content'>
                      <span
                        className='scroll-button-text'
                        style={{ color: section.isDark ? '#ffffff' : '#000000' }}>
                        <GoChevronUp className='scroll-button-icon' />
                        {' Back to Top'}
                      </span>
                    </div>
                  </button>
                ) : (
                  // Other sections, show scroll to next section button
                  <button
                    className='scroll-button'
                    onClick={() => scrollToSection(index + 1)}>
                    <div className='scroll-button-content'>
                      <span
                        className='scroll-button-text'
                        style={{ color: section.isDark ? '#ffffff' : '#000000' }}>
                        <GoChevronDown className='scroll-button-icon' />
                        {' More'}
                      </span>
                    </div>
                  </button>
                )}
              </>
            )}
          </section>
        ))}
      </div>
    </div>
  );
};

export default LandingPage;
