import client from '../controller/client';

export const getSellerById = async (sellerId) => {
  try {
    const response = await client.get(`/api/seller/${sellerId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting seller by id:', error);
    throw error;
  }
};
