import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PreferencesContext } from '../context/theme';
import { SeeMoreText } from '../components/SeeMoreText';
import { OptionsRow } from '../components/OptionsRow';
import { AvailabilityFinePrint } from '../components/AvailabilityFinePrint';
import { formatAddress, formatPrice, formatTime, calculatePrices, openMap } from '../helpers';
import { PieIcons } from '../components/icons/PIE_Icons';
import { MasonryListings } from '../components/MasonryListings';
import { ButtonGeneral } from '../components/buttonGeneral';
import { daysOfWeek } from '../constants/daysOfWeek';
import { incrementViewCount } from '../api/metricsApi';
import { getListingById } from '../api/listingApi';
import { ContentCarousel } from '../components/ContentCarousel';
import { AppBanner } from '../components/AppBanner';
import appStoreBadge from '../components/images/appStoreWhite.svg';
import googlePlayBadge from '../components/images/GooglePlayBadge.png';
import { CustomModal } from '../components/CustomModal';
import './styles/ListingDetails.css';

const ListingDetails = () => {
  const { theme } = useContext(PreferencesContext);
  const navigate = useNavigate();
  const { listingId } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [listing, setListing] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  // Increment view count when the component mounts
  useEffect(() => {
    if (!listingId) return;
    incrementViewCount(listingId).catch((error) => {
      console.error('Failed to increment view count:', error);
    });
  }, [listingId]);

  // Fetch the listing details
  useEffect(() => {
    if (!listingId) return;
    setIsLoading(true);
    getListingById(listingId)
      .then((response) => {
        setData(response);
        const foundListing = response.listings?.find((list) => list.id === listingId);
        setListing(foundListing || null);
      })
      .catch((err) => {
        console.error('Error fetching listing by ID:', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [listingId]);

  // Set the default option (if available)
  useEffect(() => {
    if (listing?.variants && listing.variants.length > 0 && !selectedOptionId) {
      setSelectedOptionId(listing.variants[0].variant_id);
    }
  }, [listing, selectedOptionId]);

  const selectedVariant = useMemo(() => {
    if (!selectedOptionId) return null;
    return listing?.variants.find((variant) => variant.variant_id === selectedOptionId);
  }, [selectedOptionId, listing?.variants]);

  const onButtonPress = () => {
    setModalVisible(true);
  };

  // A web version of the LocationHours component
  const LocationHours = () => {
    const hours = data?.location_hours || (data?.locations && data.locations[0]?.hours) || [];
    return (
      <div className='hoursColumn'>
        {hours.map((hour, index) => {
          const day = daysOfWeek.find((d) => d.value === hour.day_of_week);
          return (
            <div
              key={index}
              className='hourRow'>
              <p className='dayText'>{day ? day.label : 'Unknown'}</p>
              <p className='timeText'>
                {hour.open_time && hour.close_time
                  ? `${formatTime(hour.open_time)} - ${formatTime(hour.close_time)}`
                  : 'Closed'}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  const onVisitStore = () => {
    navigate(`/seller/${data?.seller?.seller_id}`);
  };

  // Calculate prices based on the selected variant
  const { originalPrice, finalPrice } = calculatePrices(selectedVariant);
  const hasDiscounts =
    Array.isArray(selectedVariant?.discounts) &&
    selectedVariant?.discounts.length > 0 &&
    originalPrice &&
    finalPrice !== 'Price upon request';

  if (isLoading) {
    return (
      <div className='loading-container'>
        <div className='spinner'>Loading...</div>
      </div>
    );
  }

  if (!data || !listing) {
    return (
      <div className='loading-container'>
        <p className='no-data-text'>No listing data found</p>
      </div>
    );
  }

  return (
    <>
      <AppBanner />
      <div className='container'>
        <div className='scrollContainer'>
          <div className='carouselContainer'>
            <ContentCarousel
              images={listing?.images}
              videos={listing?.videos}
            />
          </div>
          <div className='separator'></div>
          <div className='section'>
            {/* Price Row */}
            <div className='priceRow'>
              {finalPrice === 'Price upon request' ? (
                <p className='noPriceText'>{finalPrice}</p>
              ) : hasDiscounts ? (
                <>
                  <p className='finalPrice'>${formatPrice(finalPrice, true)}</p>
                  <p className='originalPrice'>${formatPrice(originalPrice, true)}</p>
                  {selectedVariant?.discounts &&
                    Array.isArray(selectedVariant?.discounts) &&
                    selectedVariant.discounts.flat().map((discount) => (
                      <p
                        key={discount.id}
                        className='discountText'>
                        {`(-${
                          discount.percentage
                            ? `${discount.amount}%`
                            : `$${formatPrice(discount.amount, true)}`
                        })`}
                      </p>
                    ))}
                </>
              ) : (
                <p className='finalPrice'>${formatPrice(finalPrice, true)}</p>
              )}
            </div>
            <p className='title'>{listing?.name}</p>
            {listing?.description && <SeeMoreText text={listing?.description} />}
            {listing?.variants && (
              <div className='subTitleContainer'>
                <p className='subtitle'>Available Options</p>
                <OptionsRow
                  options={listing.variants}
                  selectedOptionId={selectedOptionId}
                  onSelectOption={(optionId) => setSelectedOptionId(optionId)}
                />
                <AvailabilityFinePrint />
              </div>
            )}
          </div>
          <>
            <div className='separator'></div>
            <div className='section'>
              <p
                className='title'
                title='Location'>
                Location
              </p>

              <div className='address-row'>
                <PieIcons
                  name='location'
                  draw={{ size: 18 }}
                />
                <p
                  className='address-text'
                  onClick={() => {
                    openMap(
                      data?.address_line_1 ||
                        (data?.locations && data.locations[0]?.address_line_1),
                      data?.city || (data?.locations && data.locations[0]?.city),
                      data?.zip_code || (data?.locations && data.locations[0]?.zip_code),
                      data?.seller?.seller_name || data?.name,
                    );
                  }}>
                  {formatAddress(
                    data?.address_line_1 || (data?.locations && data.locations[0]?.address_line_1),
                    data?.city || (data?.locations && data.locations[0]?.city),
                    data?.state || (data?.locations && data.locations[0]?.state),
                    data?.zip_code || (data?.locations && data.locations[0]?.zip_code),
                  )}
                </p>
              </div>

              <p
                className='title'
                title='Business Hours'>
                Business Hours
              </p>
              <LocationHours />
            </div>

            <div className='separator'></div>
            <div className='section'>
              <div
                className='moreRow'
                onClick={onVisitStore}>
                <p
                  className='title moreTitle'
                  title={`More From ${data?.seller?.seller_name || data?.name}`}>
                  More From {data?.seller?.seller_name || data?.name}
                </p>
                <PieIcons
                  name='right'
                  draw={{ color: theme.colors.onBackground, size: 18 }}
                />
              </div>
              {data?.listings && (
                <MasonryListings
                  data={data}
                  listings={data.listings}
                />
              )}
            </div>
          </>
        </div>

        <>
          <ButtonGeneral
            title='Buy Now'
            margin={10}
            onPress={onButtonPress}
          />
        </>

        <CustomModal
          close={() => setModalVisible(false)}
          onPress={() => setModalVisible(false)}
          shown={modalVisible}
          color={theme.colors.lightGray}
          component={
            <div className='badges-row'>
              <a
                href='https://play.google.com/store/apps/details?id=com.j.pie.net.PIEMAIN'
                target='_blank'
                rel='noopener noreferrer'>
                <img
                  src={googlePlayBadge}
                  alt='Google Play Badge'
                  className='store-badge'
                />
              </a>
              <a
                href='https://apps.apple.com/us/app/shop-on-pie/id6504500912'
                target='_blank'
                rel='noopener noreferrer'>
                <img
                  src={appStoreBadge}
                  alt='App Store Badge'
                  className='store-badge'
                />
              </a>
            </div>
          }
          title={'Download the Shop On PIE mobile app to interact with local sellers!'}
          buttonText={'Close'}
        />
      </div>
    </>
  );
};

export default ListingDetails;
