import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './screens/LandingPage';
import TermsConditions from './screens/TermsConditions';
import PrivacyPolicy from './screens/PrivacyPolicy';
import ContactUs from './screens/ContactUs';
import AllSet from './screens/AllSet';
import AuthRedirect from './screens/AuthRedirect';
import DeleteAccount from './screens/DeleteAccount';
import SellerStore from './screens/SellerStore';
import ListingDetails from './screens/ListingDetails';
import './App.css';

function App() {
  return (
    <Router>
      <div className='App'>
        {/* Render the AppBanner so it appears on every page */}
        {/* Routing Setup */}
        <Routes>
          {/* Redirect Route */}
          <Route
            path='/redirect'
            element={<AuthRedirect />}
          />
          {/* Public Routes */}
          <Route
            path='/'
            element={<LandingPage />}
          />
          <Route
            path='/seller/:sellerId'
            element={<SellerStore />}
          />
          <Route
            path='/listing/:listingId'
            element={<ListingDetails />}
          />
          <Route
            path='/terms'
            element={<TermsConditions />}
          />
          <Route
            path='/privacy'
            element={<PrivacyPolicy />}
          />
          <Route
            path='/contact'
            element={<ContactUs />}
          />
          <Route
            path='/all-set'
            element={<AllSet />}
          />
          <Route
            path='/delete-account'
            element={<DeleteAccount />}
          />
          {/* Catch-all Route */}
          <Route
            path='*'
            element={
              <Navigate
                to='/redirect'
                replace
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
