import React, { useState, useRef, useEffect, useContext } from 'react';
import { PreferencesContext } from '../context/theme';
import { PieIcons } from './icons/PIE_Icons';
import './styles/ContentCarousel.css';

export const ContentCarousel = ({ images = [], videos = [] }) => {
  const { theme } = useContext(PreferencesContext);
  const [activeTab, setActiveTab] = useState('image'); // "image" or "video"
  const [currentIndex, setCurrentIndex] = useState(0);
  const [swipeStartX, setSwipeStartX] = useState(null);
  const [swipeDelta, setSwipeDelta] = useState(0);
  const containerRef = useRef(null);

  // Choose the data based on the active tab
  const data = activeTab === 'image' ? images : videos;
  const hasData = data && data.length > 0;

  // Reset current index when switching tabs
  useEffect(() => {
    setCurrentIndex(0);
  }, [activeTab]);

  // Touch handlers for swipe detection
  const handleTouchStart = (e) => {
    setSwipeStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (swipeStartX !== null) {
      const currentX = e.touches[0].clientX;
      setSwipeDelta(currentX - swipeStartX);
    }
  };

  const handleTouchEnd = () => {
    const threshold = 50; // minimum px swipe distance
    if (swipeDelta > threshold && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else if (swipeDelta < -threshold && currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
    setSwipeStartX(null);
    setSwipeDelta(0);
  };

  // Arrow navigation
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNext = () => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div className='modern-carousel'>
      {/* Tabs */}
      <div className='modern-carousel-tabs'>
        <div
          className={`modern-carousel-tab ${activeTab === 'image' ? 'active' : ''}`}
          onClick={() => setActiveTab('image')}>
          <PieIcons
            name='image'
            draw={{
              color: activeTab === 'image' ? theme.colors.onBackground : theme.colors.disabled,
              size: 24,
            }}
          />
          <span>Images</span>
        </div>
        <div
          className={`modern-carousel-tab ${activeTab === 'video' ? 'active' : ''}`}
          onClick={() => setActiveTab('video')}>
          <PieIcons
            name='video'
            draw={{
              color: activeTab === 'video' ? theme.colors.onBackground : theme.colors.disabled,
              size: 24,
            }}
          />
          <span>Videos</span>
        </div>
      </div>

      {/* Carousel Container */}
      <div
        className='modern-carousel-container'
        ref={containerRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}>
        <div
          className='modern-carousel-track'
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {hasData ? (
            data.map((item, idx) => (
              <div
                className='modern-carousel-slide'
                key={idx}>
                {activeTab === 'image' ? (
                  <img
                    src={item}
                    alt={`Slide ${idx}`}
                    className='modern-carousel-image'
                  />
                ) : (
                  <video
                    src={item}
                    className='modern-carousel-video'
                    controls
                    muted
                    loop
                  />
                )}
              </div>
            ))
          ) : (
            <div className='modern-carousel-slide'>
              <p>No content available</p>
            </div>
          )}
        </div>
        {hasData && (
          <>
            <button
              className='modern-carousel-arrow left'
              onClick={handlePrev}>
              &#8249;
            </button>
            <button
              className='modern-carousel-arrow right'
              onClick={handleNext}>
              &#8250;
            </button>
          </>
        )}
      </div>

      {/* Dot Pagination */}
      {hasData && (
        <div className='modern-carousel-pagination'>
          {data.map((_, idx) => (
            <div
              key={idx}
              className={`modern-carousel-dot ${idx === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};
