import React, { useContext } from 'react';
import { PreferencesContext } from '../context/theme';
import './styles/AvailabilityFinePrint.css';

export const AvailabilityFinePrint = () => {
  const { theme } = useContext(PreferencesContext);

  // Inline style to incorporate dynamic theme values
  const inlineStyle = {
    color: theme.colors.onBackground,
    fontFamily: 'Montserrat',
    fontSize: '10px',
  };

  return (
    <p
      className='availability-fineprint'
      style={inlineStyle}>
      The inventory displayed on our platform is updated regularly to reflect the latest offerings
      from local merchants. However, certain products or services may sell out quickly or have
      limited availability. We encourage you to contact the seller directly to confirm the
      availability of any item before making a purchase.
    </p>
  );
};
