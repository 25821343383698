import React, { useContext } from 'react';
import { FaVideo } from 'react-icons/fa';
import { PreferencesContext } from '../../context/theme';

export const VideoIcon = ({ size, color, onClick }) => {
  const { theme } = useContext(PreferencesContext);

  return (
    <div
      onClick={onClick}
      style={{ cursor: 'pointer' }}>
      <FaVideo
        size={size || 28}
        color={color || theme.colors.onBackground}
      />
    </div>
  );
};
