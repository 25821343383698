import React from 'react';
import * as Icons from './PIE_IconsImports';

export const PieIcons = (nameIcon) => {
  switch (nameIcon.name) {
    case 'search':
      return <Icons.Search {...nameIcon.draw} />;
    case 'location':
      return <Icons.Location {...nameIcon.draw} />;
    case 'eye':
      return <Icons.Eye {...nameIcon.draw} />;
    case 'market':
      return <Icons.Market {...nameIcon.draw} />;
    case 'clock':
      return <Icons.Clock {...nameIcon.draw} />;
    case 'categories':
      return <Icons.Categories {...nameIcon.draw} />;
    case 'image':
      return <Icons.ImageIcon {...nameIcon.draw} />;
    case 'video':
      return <Icons.VideoIcon {...nameIcon.draw} />;
    default:
      return null;
  }
};
