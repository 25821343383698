import React, { useState, useRef, useEffect, useContext } from 'react';
import { PreferencesContext } from '../context/theme';
import './styles/SeeMoreText.css';

export const SeeMoreText = ({ text, onExpand }) => {
  const [expanded, setExpanded] = useState(false);
  const [lengthMore, setLengthMore] = useState(false);
  const textRef = useRef(null);
  const { theme } = useContext(PreferencesContext);

  // When mounted, compare the scrollHeight (full height) with clientHeight (clamped height)
  useEffect(() => {
    if (textRef.current) {
      if (textRef.current.scrollHeight > textRef.current.clientHeight) {
        setLengthMore(true);
      }
    }
  }, [text]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    if (!expanded && onExpand) {
      onExpand();
    }
  };

  return (
    <div className='see-more-text-container'>
      <p
        ref={textRef}
        className={`see-more-text ${!expanded ? 'clamped' : ''}`}
        style={{ color: theme.colors.onBackground }}>
        {text}
      </p>
      {lengthMore && (
        <button
          className='read-more-button'
          onClick={toggleExpanded}>
          {expanded ? 'Read less...' : 'Read more...'}
        </button>
      )}
    </div>
  );
};
