import React, { useContext } from 'react';
import { PreferencesContext } from '../../context/theme';
import { IoStorefront } from 'react-icons/io5';

export const Market = ({ size, color, onClick }) => {
  const { theme } = useContext(PreferencesContext);

  return (
    <div
      onClick={onClick}
      style={{ cursor: 'pointer' }}>
      <IoStorefront
        size={size || 28}
        color={color || theme.colors.onBackground}
      />
    </div>
  );
};
