import React, { useEffect, useState } from 'react';
import './styles/AppBanner.css';
import appStoreBadge from './images/appStoreBlack.svg';
import playStoreBadge from './images/GooglePlayBadge.png';

export const AppBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Example: only show if on mobile and banner has not been dismissed yet
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isMobile) {
      setShowBanner(true);
    }
  }, []);

  if (!showBanner) return null;

  return (
    <div className='app-banner'>
      <p>Download our mobile app to shop small businesses near you!</p>
      <div className='app-banner-links'>
        {/iPhone|iPad|iPod/i.test(navigator.userAgent) && (
          <a
            href='https://apps.apple.com/us/app/shop-on-pie/id6504500912'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              src={appStoreBadge}
              alt='Download on the App Store'
            />
          </a>
        )}
        {/Android/i.test(navigator.userAgent) && (
          <a
            href='https://play.google.com/store/apps/details?id=com.j.pie.net.PIEMAIN'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              src={playStoreBadge}
              alt='Get it on Google Play'
            />
          </a>
        )}
      </div>
      <button
        className='dismiss-btn'
        onClick={() => setShowBanner(false)}>
        ×
      </button>
    </div>
  );
};
