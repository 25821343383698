import React, { useContext, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PreferencesContext } from '../context/theme';
import { formatPrice } from '../helpers/index';
import './styles/ListingCard.css';

const ListingCard = memo(({ listing }) => {
  const { theme } = useContext(PreferencesContext);
  const navigate = useNavigate();

  const onListingPress = () => {
    // Navigate to the listing details page.
    navigate(`/listing/${listing.id}`);
  };

  return (
    <div
      className='listing-container'
      onClick={onListingPress}
      // Optionally, you can set dynamic background from theme inline:
      style={{ backgroundColor: theme.colors.surface }}>
      <div className='listing-image-container'>
        <img
          src={
            listing.images && listing.images[0] ? listing.images[0] : '/assets/images/PIE_Logo.png'
          }
          alt={listing.name}
          className='listing-image'
        />
      </div>
      <div className='listing-footer'>
        {listing.price ? (
          <p className='listing-price'>
            {listing.multi_price ? 'from ' : ''}${formatPrice(listing.price, true)}
          </p>
        ) : (
          <p className='listing-price'>*Price upon request*</p>
        )}
        <p className='listing-name'>{listing.name}</p>
        {listing.on_sale && (
          <div className='on-sale-badge'>
            <p className='on-sale-text-badge'>On Sale!</p>
          </div>
        )}
      </div>
    </div>
  );
});

export default ListingCard;
