export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber || phoneNumber.length !== 10) {
    return phoneNumber;
  }

  const areaCode = phoneNumber.slice(0, 3);
  const centralOfficeCode = phoneNumber.slice(3, 6);
  const lineNumber = phoneNumber.slice(6, 10);

  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
};

export const formatAddress = (addressLine1, addressLine2, city, state, zipCode) => {
  const parts = [addressLine1, addressLine2, city, state, zipCode].filter(Boolean); // Removes any falsy values
  return parts.join(', ');
};

export const openMap = async (address, city, zipCode, sellerName) => {
  const destination = encodeURIComponent(`${address} ${zipCode}, ${city}`);
  // Using Google Maps URL scheme for the web:
  const link = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;

  try {
    // Log the event using your web analytics tool if available:
    // await analytics().logEvent('map_opened', {
    //   seller_name: sellerName,
    //   address: address,
    //   city: city,
    //   zip_code: zipCode,
    // });

    // Open the map in a new tab:
    window.open(link, '_blank');
  } catch (error) {
    console.error('Error opening map:', error);
  }
};

export const openAndFormatEmail = (address, subject, body) => {
  const mailtoLink = `mailto:${encodeURIComponent(address)}?subject=${encodeURIComponent(
    subject,
  )}&body=${encodeURIComponent(body)}`;
  // For web, simply setting window.location.href will trigger the mail client.
  window.location.href = mailtoLink;
};

export const callPhoneNumber = (number) => {
  // Use the 'tel:' scheme; note that 'telprompt:' is specific to React Native/ios.
  const phoneNumber = `tel:${number}`;
  window.location.href = phoneNumber;
};

export const formatPrice = (value, omitZeroCents = false) => {
  if (!value && value !== 0) return '';

  // Remove all non-digit and non-decimal characters
  const cleanedValue = String(value).replace(/[^\d.]/g, '');

  // Parse to float
  const floatValue = parseFloat(cleanedValue);
  if (isNaN(floatValue)) return '';

  // Determine if the value has non-zero cents
  const hasCents = floatValue % 1 !== 0;

  // Define formatting options based on omitZeroCents
  const options = omitZeroCents
    ? {
        minimumFractionDigits: hasCents ? 2 : 0,
        maximumFractionDigits: 2,
      }
    : {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };

  // Format with commas and appropriate decimal places
  return floatValue.toLocaleString('en-US', options);
};

export const calculatePrices = (variant) => {
  // Parse the base price from the variant
  const basePrice = parseFloat(variant?.price);

  // Initialize the return values
  let originalPrice = null;
  let finalPrice = null;

  // Check if basePrice is null, undefined, 0, or negative
  if (!basePrice || basePrice <= 0) {
    finalPrice = 'Price upon request';
    return { originalPrice, finalPrice };
  }

  // Set the original price
  originalPrice = basePrice;

  // Initialize finalPrice with the base price
  let calculatedFinalPrice = basePrice;

  // Ensure that discounts is an array and has elements
  if (Array.isArray(variant?.discounts) && variant?.discounts.length > 0) {
    variant?.discounts.forEach((discount) => {
      // Check if discount exists and has the required properties
      if (
        discount &&
        (typeof discount.amount === 'number' || typeof discount.amount === 'string') &&
        typeof discount.percentage === 'boolean'
      ) {
        // Parse the amount to a float if it's a string
        const amount =
          typeof discount.amount === 'string' ? parseFloat(discount.amount) : discount.amount;

        // Check if the parsed amount is a valid number
        if (isNaN(amount)) {
          console.error(`Invalid discount amount: ${discount.amount}`);
          // Optionally, you can skip applying this discount or handle it as needed
          return; // Skip this discount
        }

        // Apply the discount based on its type
        if (discount.percentage) {
          // Apply percentage-based discount
          calculatedFinalPrice -= (calculatedFinalPrice * amount) / 100;
        } else {
          // Apply fixed amount discount
          calculatedFinalPrice -= amount;
        }

        // Ensure the final price doesn't drop below zero
        if (calculatedFinalPrice < 0) {
          calculatedFinalPrice = 0;
        }
      }
    });
  }

  // If finalPrice after discounts is less than or equal to 0, render placeholder
  if (calculatedFinalPrice <= 0) {
    finalPrice = 'Price upon request';
  } else {
    finalPrice = calculatedFinalPrice;
  }

  return { originalPrice, finalPrice };
};

export const formatTime = (time) => {
  if (!time) return '';

  time = time.replace(/\D/g, '');

  if (time.length < 4) {
    time = time.padStart(4, '0');
  }

  let hour = parseInt(time.slice(0, 2), 10);
  let minute = time.slice(2, 4);
  const period = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12 || 12;

  return `${hour}:${minute} ${period}`;
};
